/********** Template CSS **********/

:root {
  --primary: #17a3b8;
  --secondary: #fe8800;
  --light: #f5f5f5;
  --dark: #14141f;
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #bcbcbc; /* Color of the scrollbar thumb */
  border-radius: 10px;
  border: 3px solid #f1f1f1; /* Padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #818181; /* Color of the thumb when hovered */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #acacac #f1f1f1; /* thumb and track color */
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 0px;
}

/*** Navbar ***/
.navbar {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 5;
}

.navbar-light .navbar-nav .nav-link {
  font-family: sans-serif;
  position: relative;
  margin-right: 25px;
  padding: 35px 0;
  color: #5a5a5a !important;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
  color: var(--dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

.navbar-light .navbar-brand img {
  height: 40px;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-light {
    /* position: relative; */
    background: #ffffff;
  }

  .navbar-light .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid #dddddd;
  }

  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
    color: var(--dark) !important;
  }

  .navbar-light .navbar-brand img {
    max-height: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-light {
    /* position: absolute; */
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(256, 256, 256, 0.1);
    z-index: 999;
  }

  .sticky-top.navbar-light {
    position: fixed;
    background: #ffffff;
  }

  .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    bottom: -1px;
    left: 50%;
    background: var(--primary);
    transition: 0.5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
    width: calc(100% - 2px);
    left: 1px;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact::before {
    display: none;
  }
}

/*** Hero Header ***/
.section-hero {
  background-color: #e9f7fd;
  padding: 4rem 0 3rem 0;
}

.hero {
  max-width: 1500px;
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  padding: 0 3.2rem;
  margin: 0 auto;
  align-items: center;
  gap: 0 2.6rem;
}

.heading-primary {
  font-size: 4.6rem;
  color: #333;
  font-weight: 900;
  line-height: 1.05;
  margin-bottom: 1rem;
}

.hero-description {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 2.5rem;
}

.hero-img-box {
  grid-column: 2/3;
  grid-row: 1 / 3;
}

.hero-img {
  width: 100%;
}

/* We'll create a utility class that can be used for any element by simply adding a class. */
.margin-right-btn {
  margin-right: 2.4rem;
}

.delivered-meals {
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

.delivered-imgs img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: -1.2rem;
  outline: #fdf2e9 solid 3px;
}

.delivered-text {
  font-size: 1.3rem;
  font-weight: 600;
}

.delivered-text span {
  color: #cf711f;
}

@media (max-width: 992px) {
  .hero {
    display: block;
    padding: 0 1.2rem;
  }

  .heading-primary {
    font-size: 3rem;
    line-height: 1.2;
  }

  .hero-description {
    font-size: 1.1rem;
    line-height: 1.4;
    margin-bottom: 1.3rem;
  }

  .hero-img-box {
    margin-top: 30px;
  }

  .delivered-meals {
    margin-top: 25px;
  }

  .delivered-imgs img {
    width: 2rem;
    height: 2rem;
    margin-right: -0.9rem;
  }

  .delivered-text {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .delivered-text span {
    color: #cf711f;
  }
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: var(--light) !important;
}

/*** Destination ***/
.destination img {
  transition: 0.5s;
}

.destination a:hover img {
  transform: scale(1.1);
}

/*** Package ***/
.package-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.package-item img {
  transition: 0.5s;
}

.package-item:hover img {
  transform: scale(1.1);
}

/*** Booking ***/
.booking {
  background: linear-gradient(rgba(4, 5, 7, 0.864), rgba(0, 0, 0, 0.922)),
    url(./Assets/Img/contact.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item .btn {
  background: #ffffff;
  color: var(--primary);
  border-radius: 20px;
  border-bottom: 1px solid var(--primary);
}

.team-item .btn:hover {
  background: var(--primary);
  color: #ffffff;
}

/*** Testimonial ***/
.testimonial-carousel::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 200px;
  }
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 300px;
  }
}

.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: #ffffff !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  font-weight: normal;
  border: 1px solid #ffffff;
  border-radius: 3px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.contact-info {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.contact-info-icon {
  margin-bottom: 15px;
}
.contact-info-item {
  background: #e9f7fd;
  padding: 30px 0px;
}

.contact-info-icon i {
  font-size: 48px;
  color: #17a3b8;
}

.mobile-sidebar {
  height: 100vh;
  width: 300px;
  position: fixed;
  z-index: 1;
  left: 0;
  background-color: white;
  transform: translateX(-100%); /* Initially hidden */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.mobile-sidebar.open {
  transform: translateX(0); /* Slide in */
}

.mobile-sidebar-list {
  list-style: none;
  margin-top: 100px;
}
.mobile-sidebar-list li {
  margin-bottom: 38px;
}
.mobile-sidebar-list li a {
  color: #121212;
  font-weight: 600;
}
